import React from 'react';
import { useSelector } from 'react-redux';
import { navigate, Link } from 'gatsby';
import clsx from 'clsx';
import { Box, createStyles, makeStyles, Paper, Snackbar, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AnimateBackground } from './AnimateBackground';
import logo from '../../assets/logo_white.png';
import { selectAuth } from 'app';
import { verificationInvalid } from 'app/helpers';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) =>
  createStyles<string, AuthPageProps>({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      '& > main': {
        position: 'relative',
        zIndex: 100,
        height: '100%',
        width: '100%',
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
          overflow: 'auto',
        },
      },
    },

    container: {
      minHeight: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.white,
    },

    logo: {
      marginTop: theme.spacing(4),
      '& > a': {
        display: 'block',
        opacity: 0.6,
        transition: theme.transitions.create(['opacity'], { duration: 600 }),
        '&:hover': {
          opacity: 1,
        },
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(8),
      },
    },

    btnContainer: {
      position: 'absolute',
      width: '100%',
      height: theme.spacing(2),
    },

    homeBtn: {
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(1),
      color: '#ffffff',
      border: 0,
    },

    article: (props) => ({
      display: 'flex',
      overflow: 'hidden',
      margin: theme.spacing(4),
      maxWidth: 800,
      '& > *': {
        flexGrow: 0.5,
        flexBasis: 0,
        padding: theme.spacing(2),
      },
      '& > header': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: props.headercolor,

        '& > img': {
          opacity: 0,
          maxWidth: '220px',
          maxHeight: '220px',
          transform: 'translateY(-50px)',
          transition: theme.transitions.create(['opacity', 'transform'], { duration: 1000 }),
        },

        '&.slideIn': {
          '& > img': {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
      },
      '& > article': {
        display: 'flex',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        margin: theme.spacing(2),
        maxWidth: 400,
        '& > header': {
          padding: theme.spacing(4, 0),
          '& > img': {
            width: 200,
          },
        },
      },
    }),
  }),
);

export type AuthPageProps = {
  bgcolor: string;
  headercolor: string;
  img: React.ReactNode;
  children: React.ReactNode;
};

export function AuthPage(props: AuthPageProps) {
  const { img, bgcolor, children } = props;
  const classes = useStyles(props);
  const { userInfo, authenticated, status } = useSelector(selectAuth);

  const expire = verificationInvalid(userInfo?.metadata?.creationTime, userInfo?.emailVerified);

  const [warning, setWarning] = React.useState<boolean>(false);
  const [slideIn, setSlideIn] = React.useState(false);

  React.useEffect(() => {
    setSlideIn(true);
  }, []);

  React.useEffect(() => {
    if (authenticated && !expire) navigate('/dashboard', { replace: true });
    if (expire) navigate('/auth/verifyEmail', { replace: true });
  }, [authenticated]);

  // effect to show warning
  React.useEffect(() => {
    if (status?.error && !warning) {
      setWarning(true);
    }
  }, [status]);

  if (authenticated !== false && !expire) {
    // waiting for restoring auth state from local
    return null;
  }

  return (
    <div className={classes.root}>
      <AnimateBackground bgcolor={bgcolor} />
      <main>
        <div className={classes.btnContainer}>
          <Button
            variant="outlined"
            size="large"
            className={classes.homeBtn}
            onClick={() => navigate('/')}
            startIcon={<ArrowBackIcon />}>
            Back to Home
          </Button>
        </div>

        <div className={classes.container}>
          <Box className={classes.logo}>
            <Link to="/">
              <img src={logo} width={150} height="100%" />
            </Link>
          </Box>
          <Paper className={classes.article} component="article">
            {img && <header className={clsx({ slideIn })}>{img}</header>}
            <section>{children}</section>
          </Paper>
        </div>
      </main>

      <Snackbar open={warning} autoHideDuration={6000} onClose={() => setWarning(false)}>
        <Alert onClose={() => setWarning(false)} severity="error">
          {status?.error}
        </Alert>
      </Snackbar>
    </div>
  );
}
