import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';
import { Credential, selectAuth } from 'app';
import { verifyEmail } from 'app/authSlice';
import { AuthPage } from './AuthPage';
import mobile_login from './mobile_login.png';
import { WaitButton } from '../WaitButton';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Form, TextField, createValidator, mustBeEmail, notEmpty } from 'components/form';
import { verificationInvalid } from 'app/helpers';

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  }),
);

const validate = createValidator<Credential>({
  email: [notEmpty('Email can not be empty'), mustBeEmail('Invalid email address')],
});

export function VerifyEmail() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { status, authenticated, userInfo } = useSelector(selectAuth);
  const [sent, setSent] = React.useState(false);
  const expire = verificationInvalid(userInfo?.metadata?.creationTime, userInfo?.emailVerified);

  const verifySent = () => {
    setSent(true);
  };

  React.useEffect(() => {
    if (!authenticated) navigate('/auth/signin', { replace: true });
    else {
      if (!expire) navigate('/dashboard', { replace: true });
    }
  }, [authenticated, expire]);

  const handleSumbit = (data?: Credential) => {
    dispatch(verifyEmail(data?.email, verifySent));
  };

  return (
    <AuthPage img={<img src={mobile_login} />} headercolor={cyan[300]} bgcolor={cyan[600]}>
      <Box p={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight={380}>
        <Typography variant="h6" className={classes.heading}>
          Verify email address
        </Typography>

        <Box mt={2} width="100%" display="flex" flexDirection="column">
          <Typography color="textSecondary" variant="body2" align="center" paragraph>
            {userInfo?.email
              ? `Your email address ${userInfo.email} has NOT been verified yet and your account is temporarily locked. Please verify your email to continue accessing the PiP+ program.`
              : "You'll get email alerts when you have new module or survey available. Please provide us your contact email for verification."}
          </Typography>
        </Box>

        {(userInfo?.email || sent) && (
          <Box mb={2}>
            <Alert severity={sent ? 'success' : 'info'}>
              <AlertTitle>{sent ? 'Verification email sent' : "Can't find the verification email?"} </AlertTitle>
              {sent
                ? 'A verification email has been sent to your email address. Please click the link in the email.'
                : 'If you can’t find the verification email we sent you when you first signed up, try checking your junk or spam mail. Alternatively, you can click the button below to send a new email.'}
            </Alert>
          </Box>
        )}

        <Form<Credential>
          initial={{
            email: '',
          }}
          onSubmit={handleSumbit}
          validate={validate}
          style={{ width: '100%' }}>
          {!userInfo?.email && !sent && (
            <React.Fragment>
              <TextField name="email" variant="outlined" label="Contact email" fullWidth required />

              <Box mt={2}>
                <WaitButton
                  fullWidth
                  color="primary"
                  variant="contained"
                  type="submit"
                  wait={status?.type === 'request'}>
                  Verify
                </WaitButton>
              </Box>
            </React.Fragment>
          )}

          {userInfo?.email && !sent && (
            <Box mt={2}>
              <WaitButton
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => handleSumbit()}
                wait={status?.type === 'request'}>
                Verify
              </WaitButton>
            </Box>
          )}
        </Form>
      </Box>
    </AuthPage>
  );
}
