import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import range from 'lodash/range';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      margin: 0,
      padding: 0,

      '& > li': {
        position: 'absolute',
        display: 'block',
        listStyle: 'none',
        width: 30,
        height: 30,
        background: 'rgba(255, 255, 255, 0.2)',
        animation: '$animate 25s linear infinite',
        bottom: -100,
      },
    },

    '@keyframes animate': {
      '0%': {
        transform: 'translateY(0) rotate(0deg)',
        opacity: 1,
        borderRadius: 0,
      },

      '100%': {
        transform: 'translateY(-120vh) rotate(720deg)',
        opacity: 0,
        borderRadius: '50%',
      },
    },
  }),
);

export type AnimateBackgroundProps = {
  bgcolor: string;
};

export function AnimateBackground(props: AnimateBackgroundProps) {
  const classes = useStyles();
  return (
    <ul className={classes.root} style={{ backgroundColor: props.bgcolor }}>
      {range(0, 30).map((i) => {
        const left = (Math.random() * 100) % 9;
        const transparent = (Math.random() * 100) % 5;
        const size = (Math.random() * 100) % 70;
        const delay = (Math.random() * 100) % 25;
        const style = {
          left: `${5 + left * 10}vw`,
          width: 20 + size,
          height: 20 + size,
          backgroundColor: `rgba(255, 255, 255, ${0.1 + transparent / 10})`,
          animationDelay: `${delay}s`,
        };
        return <li key={i} style={style} />;
      })}
    </ul>
  );
}
